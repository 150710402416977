<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import type { GamesMenu } from "@/types";

const props = withDefaults(
	defineProps<{
		type?: "link" | "button";
		menu: GamesMenu;
		showLobby?: boolean;
	}>(),
	{ type: "link" }
);

const { t } = useT();
const isGuest = useIsGuest();
const { isTabActive, getTitle, lobbyActive, activeUrl } = useNavigationGame();
const { isExpanded } = useGameSearch();

const sliderRef = ref();
const showCatalogGames = useState<boolean>("show-catalog-games");
const emit = defineEmits<{ (event: "clickTab", link: string): void }>();

const filteredMenu = computed(() => props.menu?.filter((item) => !(isGuest.value && item.title === "Favorites")));

const activeSlide = computed(() => {
	const menu = props.menu || [];
	const index = menu.findIndex((item) => isTabActive(item?.url || ""));
	if (props.showLobby && index === 0) {
		return 2;
	}

	return index !== -1 ? index + 1 : 1;
});

const sliderOptions: KeenSliderOptions = {
	mode: "free-snap",
	slides: {
		perView: "auto",
		spacing: 0
	}
};

const scrollToActiveSlide = () => {
	setTimeout(() => {
		if (sliderRef.value) {
			const { slider } = sliderRef.value;
			const maxIdx = slider?.track?.details?.maxIdx ?? 0;
			const slideToMove = Math.min(activeSlide.value - 1, maxIdx);

			slider?.moveToIdx(slideToMove, false, { duration: 800 });
		}
	}, 500);
};

const handleClick = (url: string) => {
	const isRootUrl = url === "/";
	showCatalogGames.value = !isRootUrl;
	emit("clickTab", !isRootUrl ? url : "/home/lobby/");

	if (props.type === "button") {
		activeUrl.value = url;
	}

	nextTick(() => {
		scrollToActiveSlide();
	});
};

const handleGuestChange = (value: boolean) => {
	if (!value && props.showLobby) {
		lobbyActive();
	}

	nextTick(() => {
		setTimeout(() => {
			sliderRef?.value?.slider?.update();
			scrollToActiveSlide();
		}, 500);
	});
};

onMounted(() => {
	nextTick(() => {
		scrollToActiveSlide();
	});
});

const unwatchSliderUpdate = watch(
	() => isExpanded.value,
	async () => {
		await nextTick();
		setTimeout(() => {
			sliderRef.value?.slider?.update();
		}, 500);
	}
);

const unwatchHandleGuestChange = watch(isGuest, handleGuestChange);

if (process.client) {
	watch(activeSlide, () => {
		setTimeout(() => {
			scrollToActiveSlide();
		}, 500);
	});
}

onUnmounted(() => {
	unwatchSliderUpdate();
	unwatchHandleGuestChange();
});
</script>

<template>
	<div v-if="menu && menu.length > 0" id="nav-games" :class="['menu-navigation', { 'is-guest': isGuest }]">
		<ASlider ref="sliderRef" :options="sliderOptions">
			<div class="keen-slider__slide">
				<OSearchGames :type="showLobby ? 'popper' : 'page'" />
			</div>
			<div v-if="showLobby" class="keen-slider__slide">
				<MNavigationGame
					link="/"
					:active="isTabActive('/')"
					icon-link="24/lobby"
					:title="t('Lobby')"
					data-tid="nav-lobby"
					:type="type"
					@click-tab="handleClick"
				/>
			</div>

			<div v-for="item in filteredMenu" :key="item.url" class="keen-slider__slide">
				<MNavigationGame
					:link="item.url || ''"
					:active="isTabActive(`${item.url}`)"
					:icon-html="item.img || ''"
					:title="item.title || ''"
					:data-tid="`nav-${getTitle(item?.title)}`"
					:type="type"
					@click-tab="handleClick"
				/>
			</div>
		</ASlider>
	</div>
</template>

<style lang="scss">
.menu-navigation {
	&.is-guest {
		.keen-slider__slide:has([data-tid*="nav-favorites"]) {
			display: none;
		}
	}
}
</style>
<style lang="scss" scoped>
.keen-slider__slide {
	padding: 0 2px;

	@include media-breakpoint-up(md) {
		&:not(:first-child) {
			display: inline-flex;
			flex: 1;
			flex-shrink: 0;
		}
	}
}
.menu-navigation {
	width: 100%;
	position: sticky;
	top: var(--top-height);
	background-color: var(--theme-dark);
	padding: 8px 0;
	z-index: 3;

	@include media-breakpoint-down(md) {
		.keen-slider__slide:first-child {
			padding-left: 16px;
		}

		.keen-slider__slide:last-child {
			padding-right: 16px;
		}
	}
}
</style>
